*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  font-family: sans-serif;
}

.app{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
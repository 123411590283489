header{
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  height:60px;
  background: #000;
}

.logo{
  text-decoration: none;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.favoritos{
  text-decoration: none;
  cursor: pointer;
  background-color: #fff;
  padding: 5px 14px;
  color: #000;
  border-radius: 4px;
}
.meus-filmes{
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.meus-filmes h1{
  margin-bottom: 14px;
}

.meus-filmes span{
  font-size: 18px;
  font-weight: 600;
}

.meus-filmes ul{
  width: 720px;
}

.meus-filmes ul li{
  list-style: none;
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
}

.meus-filmes div{
  display: flex;
  justify-content:center;
  align-items: center;
}

.meus-filmes div a{
  text-decoration: none;
  color: #116feb;
  margin-right: 14px;
}

.meus-filmes ul li button{
  padding: 10px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  background-color: #dc3545 ;
  color: #fff;
  border-radius: 4px;
}